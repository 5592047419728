import request from './base/request';

export const getListReductionTargetApi = (params) => {
  return request
    .get('reduction-targets',  { params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const createReductionTargetApi = (data) => {
  return request
    .post('reduction-targets', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getReductionTargetDetailApi = (id, type = 1) => {
  return request
    .get(`reduction-targets/${id}?type=${type}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteReductionTargetApi = (id) => {
  return request
    .delete(`reduction-targets/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
}

export const updateReductionTargetApi = (id, data) => {
  return request
    .put(`reduction-targets/${id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const checkintegrateTarget = () => {
  return request
    .get(`reduction-targets/check-can-integrate`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDataRefGuidelinesICP = () => {
  return request
    .get('icp-references')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const checkIntegrateData = (params) => {
  return request
    .get(`reduction-targets/integrate?${params}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};