import { evaluate } from 'mathjs'
import { formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma} from '@/utils/convertNumber';
import * as wjGrid from "@mescius/wijmo.grid";

export const calcRangeYearByStartAndEndData = (data, key) => {
  const { item, startYearTagetVal, endYearTagetVal, rangeYear, endYear, startYear } = data
  const rangeValue = evaluate(`(${formatValue(endYearTagetVal)} - ${formatValue(startYearTagetVal)}) / ${formatValue(rangeYear)}`)
  const targetEmissions = typeof item[key] !== 'object' ? JSON.parse(item[key]) : item[key]

  for (let index = startYear + 1; index < endYear; index++) {
    targetEmissions[index] = addThoundSandComma(evaluate(`${formatValue(targetEmissions[index - 1])} + ${formatValue(rangeValue)}`))
  }

  return targetEmissions
}

export const keyYypeInput = (type) => {
  return type ? 'target_emissions_auto' : 'target_emissions';
}

export class RestrictedMergeManager extends wjGrid.MergeManager {
  getMergedRange(p, r, c, clip = true) {
    var rng = new wjGrid.CellRange(r, c);
    if (p.cellType === wjGrid.CellType.ColumnHeader || p.cellType === wjGrid.CellType.RowHeader) {
      return super.getMergedRange(p, r, c, clip);
    }
    for (var i = rng.row; i < p.rows.length - 1; i++) {
      if (p.getCellData(i, rng.col, true) != p.getCellData(i + 1, rng.col, true) || p.getCellData(i,0, true) != p.getCellData(i + 1, 0, true)) break;
      rng.row2 = i + 1;
    }
    for (var i = rng.row; i > 0; i--) {
      if (p.getCellData(i, rng.col, true) != p.getCellData(i - 1, rng.col, true) || p.getCellData(i,0, true) != p.getCellData(i - 1, 0, true)) break;
      rng.row = i - 1;
    }
    return rng;
  }
};
