import i18n from '@/lang/i18n';
import { math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf'

const formatDataList = (moment, startMonth, type = 'month') => {
  let dataList = [];
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  if (startMonth > currentMonth) {
    currentYear = currentYear - 1;
  }
  let startYear = 2017;
  const latestDate = moment(`${currentYear}-${startMonth}`, 'YYYY-MM').add(11, 'months').format('YYYY-MM');
  let order = 1;
  let month = moment(`${startYear}-${startMonth}`, 'YYYY-MM');
  let itemCount = 0;
  while (month.isSameOrBefore(latestDate)) {
    if (type === 'year') {
      dataList.push({
        selected_range: month.format('YYYY'),
        start_at: month.format('YYYY-MM'),
        end_at: month.add(11, 'months').format('YYYY-MM'),
        order: order,
        year: startYear,
      })
      startYear += 1;
    }
    if (type === 'half-year') {
      dataList.push({
        selected_range: `${order % 2 === 1 ? 1 : 2}-${startYear}`,
        start_at: month.format('YYYY-MM'),
        end_at: month.add(5, 'months').format('YYYY-MM'),
        order: order % 2 === 1 ? 1 : 2,
        year: startYear,
      })
      if (order % 2 === 0) {
        startYear += 1;
      }
    }
    if (type === 'quarter-year') {
      dataList.push({
        selected_range: `${order % 4 === 0 ? 4 : order % 4}-${startYear}`,
        start_at: month.format('YYYY-MM'),
        end_at: month.add(2, 'months').format('YYYY-MM'),
        order: order % 4 === 0 ? 4 : order % 4,
        year: startYear,
      })
      if (order % 4 === 0) {
        startYear += 1;
      }
    }
    if (type === 'month') {
      dataList.push({
        selected_range: month.format('YYYY/MM'),
        start_at: month.format('YYYY-MM'),
        end_at: month.format('YYYY-MM'),
        order: order,
        year: month.format('YYYY'),
      })
    }
    itemCount++;
    if (type === 'quarter-duration-month') {
      month.add(2, 'months').format('YYYY-MM')
      if (order % 4 === 0) {
        dataList.push({ itemCount, value: startYear })
        itemCount = 0;
        startYear += 1;
      }
    }
    if (type === 'duration-month-year') {
      let monthCurrent = new Date(month).getMonth();
      if ((monthCurrent + 1) % 12 === 0) {
        dataList.push({ itemCount, value: startYear })
        itemCount = 0;
        startYear += 1;
      }
      else if (monthCurrent + 1 === startMonth - 1 && startYear === currentYear + 1) {
        dataList.push({ itemCount, value: startYear })
      }
    }
    month.add(1, 'months');
    order++;
  }
  return dataList;
}

const getPcafPayload = (filterType, currencyType) => {
  let pcafType = null;
  pcafType = filterType === 2 ? currencyType : null;
  return [[filterType], pcafType];
}

const getUnitText = (filterType, currency, unit = 't-CO2') => {
  let text = '';
  if ([1, 3].includes(filterType)) {
    text = unit;
  } else {
    switch (currency) {
      case 1:
        // text = '円';
        text = i18n.t('dashboard_main.label_yen')
        break;
      case 2:
        text = 'USD';
        break;
      case 3:
        text = 'EUR';
        break;
      default:
        break;
    }
  }
  return text;
}

const isTotalEmission = (type) => {
  return [1, 3].includes(type);
}

const calcTotalValue = ({type, currency}, totalEmission, totalAmount) => {
  let totalValue = 0;
  if ([1, 3].includes(type)) {
    totalValue = formatBigNumber(math.evaluate(`${totalEmission?.toString()}`), 50);
  } else {
    if (currency === 1) {
      const currencyValue = 1000000
      totalValue = formatBigNumber(math.evaluate(`${totalAmount?.toString()} / ${currencyValue.toString()}`), 50)
    } else  {
      const currencyValue = 1000
      totalValue = formatBigNumber(math.evaluate(`${totalAmount?.toString()} / ${currencyValue.toString()}`), 50)
    }
  }
  return totalValue;
}

const getLabelText = (type) => {
  let text = '';
  switch (type) {
    case 1:
      text = i18n.t('dashboard_main.radio_select_ghg_emissions_');
      break;
    case 2:
      text = i18n.t('dashboard_main.radio_select_investment_and_financing_amount');
      break;
    case 3:
      text = i18n.t('dashboard_main.label_financed_emissions');
      break;
  }
  return text;
}


const getDataType = (dataTypes, category = null, method) => {
  if(category) { // get type case item has category
    const dataTypeCategory = []
    dataTypes.forEach(dataType => {
      const itemTypeCategory = {
        text: `${category.name}_${dataType.name}`,
        text_ik: `${category.name}_${dataType.name}`,
        text_select: `${method.text}_${category.name}_${dataType.name}`,
        hasCheckbox: true,
        row_num_pattern: method.row_num_pattern,
        type_classify: category.id,
        data_type: dataType.id
      }
      dataTypeCategory.push(itemTypeCategory)
    })
    return dataTypeCategory
  } else { // get type case item don't has category
    const dataTypeCategory = []
    dataTypes.forEach(dataType => {
      const itemTypeCategory = {
        text: `${dataType.name}`,
        text_ik: `${dataType.name}`,
        text_select: `${method.text}_${dataType.name}`,
        hasCheckbox: true,
        row_num_pattern: method.row_num_pattern,
        data_type: dataType.id
      }
      dataTypeCategory.push(itemTypeCategory)
    })
    return dataTypeCategory
  }
}

const defineDataTypeByMethod = (pcafMethods, responseData) => {
  const dataTypeDetail = []
  pcafMethods.forEach((method, index) => {
    const dataTypes = responseData[index + 1 + 4] // +4 method ghg
    if(index === 0) { // handle for method 1 & 2
      const listDataTypeMethod1 = getDataType(dataTypes, PCAF_CATEGORY_TYPE_METHOD_1[0], method)
      const listDataTypeMethod2 = getDataType(dataTypes, PCAF_CATEGORY_TYPE_METHOD_1[1], method)
      dataTypeDetail.push([...listDataTypeMethod1, ...listDataTypeMethod2])
    } else if(index === 1) {
      const listDataTypeMethod1 = getDataType(dataTypes, PCAF_CATEGORY_TYPE_METHOD_2[0], method)
      const listDataTypeMethod2 = getDataType(dataTypes, PCAF_CATEGORY_TYPE_METHOD_2[1], method)
      dataTypeDetail.push([...listDataTypeMethod1, ...listDataTypeMethod2])
    } else { // handle for method 3,4,5,6,7
      const listDataTypeByMethod = getDataType(dataTypes, null, method)
      dataTypeDetail.push(listDataTypeByMethod)
    }
  })

  return dataTypeDetail
}

const setDataTypeForPcafMethod = (pcafMethods, responseData) => {
  const dataTypeDetail = defineDataTypeByMethod(pcafMethods, responseData)
  pcafMethods.forEach((method, index) => {
    method.hasCheckbox = false
    method.methods = dataTypeDetail[index]
  })
  return pcafMethods
}

const handleUpdateRowNumpattern = (listData) => {
  const responseDataObj = {}
  const listId = []
  listData.forEach(dataItem => {
    // check & push row_num_pattern in list responseData
    if(dataItem?.row_num_pattern && ![47,48].includes(dataItem?.row_num_pattern)) {
      if(!responseDataObj[dataItem.row_num_pattern]) {
        responseDataObj[dataItem.row_num_pattern] = dataItem
      } else {
        responseDataObj[dataItem.row_num_pattern].type_data.push(...dataItem.type_data)
      }
    } else if(dataItem?.row_num_pattern) {
      if(!responseDataObj[dataItem.row_num_pattern]) {
        const data = {
          row_num_pattern: dataItem.row_num_pattern,
          1: {
            type_data: []
          },
          2: {
            type_data: []
          }
        }
        data[dataItem.type_classify].type_data.push(...dataItem.type_data)
        responseDataObj[dataItem.row_num_pattern] = data
      } else {
        responseDataObj[dataItem.row_num_pattern][dataItem.type_classify].type_data.push(...dataItem.type_data)
      }
    } else {
      listId.push(dataItem)
    }
  })
  // delete type_classify if type_data empty
  const dataPcafs = Object.values(responseDataObj)?.map(item => {
    if(item[1] && item[1]?.type_data?.length === 0) {
      delete item[1]
    }
    if(item[2] && item[2]?.type_data?.length === 0) {
      delete item[2]
    }
    return item
  })
  return [...listId, ...dataPcafs]
}

export { formatDataList, getPcafPayload, getUnitText, isTotalEmission, calcTotalValue, getLabelText, setDataTypeForPcafMethod, handleUpdateRowNumpattern };
