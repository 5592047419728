import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf';

export const handleGetScopeCategories = (pattern_ids,scopeCategoriesData, dataType = {}) => {
    let scopeCategoriesList = []

    scopeCategoriesData.forEach(item => {
      if (!item?.methods) {
        scopeCategoriesList.push({
          text: item.text,
          value: item.row_num_pattern,
        });
      } else if (item.methods) {
        item.methods.forEach(itemLayer1 => {
          if (!itemLayer1.methods) {
            scopeCategoriesList.push({
              text: `${item.text}_${itemLayer1.text}`,
              value: itemLayer1.row_num_pattern,
            });
          } else if (itemLayer1.methods) {
            itemLayer1.methods.forEach(itemLayer2 => {
              scopeCategoriesList.push({
                text: `${item.text}_${itemLayer1.text}_${itemLayer2.text}`,
                value: itemLayer2.row_num_pattern,
              });
            })
          }
        })
      }
    })
  let patternIdArr = getRowNumPatterns(pattern_ids);
  const dataPatternByType = getMethodByDataType(pattern_ids, dataType);
  const dataPatternFilterByPatternId = scopeCategoriesList.filter(item => patternIdArr.includes(item.value));
  
  return filterDataPcaf(dataPatternFilterByPatternId, dataPatternByType);
}

const getRowNumPatterns = (data) => {
  const patterns = data;
  const combinedValues = [];

  patterns.forEach(item => {
    if (typeof item === 'object' && item.row_num_pattern !== undefined) {
      combinedValues.push(item.row_num_pattern);
    } else if (typeof item !== 'object') {
      combinedValues.push(item);
    }
  });

  return combinedValues;
}
// Get data type
const getMethodByDataType = (pattern_ids, dataType) => {
  let dataList = []
  pattern_ids.forEach(element => {
    if (typeof element === 'object' && element !== null) {
      const num = element.row_num_pattern;
      let keyType = null;
      const numToKeyType = {
        47: 5,
        48: 6,
        49: 7,
        50: 8,
        51: 9,
        52: 10,
        53: 11
      };

      if (numToKeyType.hasOwnProperty(num)) {
        keyType = numToKeyType[num];
      }
      for (let key in element) {
        if (typeof element[key] === 'object' && element[key] !== null) {
          if (element[key]['type_data'] && element[key]['type_data'].length ) {
            element[key]['type_data'].forEach(item => {
              const categoryMap = {
                47: PCAF_CATEGORY_TYPE_METHOD_1,
                48: PCAF_CATEGORY_TYPE_METHOD_2
              };

              const categories = categoryMap[num];
              const classify = categories ? categories.find(i => i.id == key)?.name : '';
              const typeName = dataType[keyType].find(i => i.id === item).name
              dataList.push(`${classify}_${typeName}`);
            })
          } else if (element[key].length && key == 'type_data') {
            element[key].forEach(item => {
              dataList.push(dataType[keyType].find(i => i.id === item).name);
            })
          }
        } 
      }
    }
    
  });
  return dataList;
}

const filterDataPcaf = (dataAll, dataPCAf) => {
  const output = dataAll.filter(item => {
    if (item.value < 47 || item.value > 53) {
      return true;
    } else if (item.value >= 47 && item.value <= 53) {
      return dataPCAf.some(bbItem => item.text.includes(bbItem));
    }
    return false;
  });
  return output
}

